import React, { Component } from 'react';
import * as Chord from "tonal-chord";
import * as Note from "tonal-note";
import Modal from 'react-modal';
import Keys from './keys';

//import {MidiListeners} from './listeners';

class AdvancedChordButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chord: [],
            oct: "",
            notesInChord: [],
            midiNotesInChord: [],
            showModal: false,
            activeInversion: "",
            prevInversion: "",
            hoverNote: ""
        };
    }




    componentWillUnmount() {
        //MidiListeners.removeListener(this.handleMidiEvent);
    }


    
    render() {
        
        return (
            <React.Fragment>
                
            <div className="btn-wrap">
                <div className={"chordButton " + (this.props.selectable ? "active" : "") } key={this.props.note + "_" + this.props.index} 
                    onTouchStart={e => {e.preventDefault(); this.advancedPlayChord()}}
                    onMouseDown={e => {e.preventDefault(); this.advancedPlayChord()}}
                    
                    onMouseUp={e => {e.preventDefault(); this.stopChord()}}
                    onTouchEnd={e => {e.preventDefault(); this.stopChord()}}

                >
                    {this.props.note}
                    <div className="chordType">{this.props.chord}</div>
                    <div className="btn-bg"></div>
                    </div>
                    <div className={"editOptions " + (this.props.editable ? "show" : "hide")}>
                        <img alt="" className="clickable" src="icons/angle-left.svg" onClick={e => this.move(this.props.index, "left")}/>
                        <img alt="" className="clickable" src="icons/pencil.svg" onClick={e => this.showModal()}/> 
                        <img alt="" className="clickable" src="icons/angle-right.svg" onClick={e => this.move(this.props.index, "right")}/> 
                    </div>
                    
            </div>
            <Modal
                        isOpen={this.state.showModal}
                        contentLabel="Show Options"
                        className="Modal"
                        outputDeviceName={this.state.outputDeviceName}
                        inputDeviceName={this.state.inputDeviceName}
                        overlayClassName="Overlay OverlayStart"
                        appElement={document.getElementById('root')}
                    >
                    <h2><img alt="" src="icons/music-alt.svg"/> CHORD OPTIONS</h2>
                    <strong>{this.props.note + this.props.chord}</strong>

                    <Keys oct={this.state.oct} hover={this.state.hoverNote} midiNotesInChord={this.state.midiNotesInChord} notesInChord={this.state.notesInChord}></Keys>
                        <div className="chordButtonFat"
                            onMouseDown={e => {e.preventDefault(); this.advancedPlayChord()}}
                            onMouseUp={e => {e.preventDefault(); this.stopChord()}}
                            onTouchStart={e => {e.preventDefault(); this.advancedPlayChord()}}
                            onTouchEnd={e => {e.preventDefault(); this.stopChord()}}
                            >
                            Play Chord
                        </div>
                    <form className="clear">

                        <div className="form-group invert pt-4">
                         <strong>INVERT</strong>
                        <ul className="list-group">
                    {   
                        this.state.notesInChord.map((note, i)=>
                        <React.Fragment key={note + "_" + i}>   
                            
                                <li 
                                    onMouseEnter={e => this.markNote(this.state.midiNotesInChord[i])} 
                                    onMouseLeave = {e => this.markNote("")} 
                                    key={note + "_" + i}
                                    className="list-group-item invertBox">
                                
                                    {(note === "E#" ? "F" : (note === "B#" ? "C" : note ))}

                                    <div className="buttonGroup"> 
                                    <button type="button" className="btn btn-light" onClick={e => this.setInversion("down", i)}>- Oct</button>
                                    <button type="button" className="btn btn-light" onClick={e => this.setInversion("up", i)}>+ Oct</button>
                                    </div>
                                </li>
                            
                        </React.Fragment>
                        )

                    }
                                <li className="list-group-item invertBox">
                                    Chord Octave
                                    <div className="buttonGroup"> 
                                    <button type="button" className="btn btn-light" onClick={e => this.setOct(-1)}>- Oct</button>
                                    <button type="button" className="btn btn-light" onClick={e => this.setOct(1)} >+ Oct</button>
                                    </div>
                                </li>
                    </ul>
                            
                      </div>
                      
                    </form>
                    <strong>DELETE CHORD </strong><img alt="" className="clickable" src="icons/trash.svg" onClick={e => this.delete(this.props.index)}/> 
                    
                    <button className="modal-btn" onClick={e => this.showModal(e)}>OK<img src="icons/angle-right.svg" alt="OK"/></button>
                
            </Modal>
            </React.Fragment>
        );
    }

    markNote = (note) =>{
        console.log("hover " + note)
        this.setState({
            hoverNote: note
        })
    }

    setInversion = (e, i) =>{

        let notes = this.state.midiNotesInChord
        if(e === "up"){
            notes[i] = notes[i] + 12
        }
        else if(e === "down"){
            notes[i] = notes[i] - 12
        }

        this.setState({
            midiNotesInChord: notes
        })

        this.props.setMappedChords(this.props.index, this.state.midiNotesInChord)
    }

    setOct = (val) =>{
        if(val === -1){
            this.setState({
                oct: this.state.oct -1
            })
        }

        else if (val === 1){
            this.setState({
                oct: this.state.oct +1
            })
        }

        let transposedChord = []
        for (let midiNote of this.state.midiNotesInChord){
            midiNote = midiNote + (12 * val);
            console.log(midiNote)
            transposedChord.push(midiNote)
        }

        this.setState({
            midiNotesInChord: transposedChord
        })



        this.props.setMappedChords(this.props.index, transposedChord)
    }

    showModal = () =>{
        this.props.hideApp()
        this.setState({
            showModal : !this.state.showModal
        })
    }

    move = (index, dir) =>{
        this.props.moveAdvancedChordButton(index, dir)
    }

    delete = (index) =>{
        this.props.setMappedChords(this.props.index, "")
        this.props.deleteAdvancedChordButton(index);
        this.props.hideApp()
    }

    componentDidMount = () => {
        // Readable Notes
        let notesInChord = Chord.notes(this.props.note + this.props.chord)
        let midiNotesInChord = []

        // MIDI Notes
        let prevNote = 60
        let count = 0
        for (let chordNote of notesInChord){
            let note = Note.midi(chordNote + this.props.oct)
            count += 1;
            if (note < prevNote){
                note += 12
            }
            console.log(note)
            prevNote = note
            midiNotesInChord.push(note)
        }

        this.setState({
            oct: this.props.oct,
            notesInChord: notesInChord,
            midiNotesInChord: midiNotesInChord,
            inversions: count,
            activeInversion: 0,
            prevInversion: 0
        })

        this.props.setMappedChords(this.props.index, midiNotesInChord)
    }

    advancedPlayChord = () => {
        //console.log(id)
        console.log(this.props.note + this.props.chord)
        let midiNotesInChord = this.state.midiNotesInChord
        for(let chordNote of midiNotesInChord){
            this.props.sendMidi(chordNote)
        }
        console.log("####################")
    }

    stopChord = () =>{
        let midiNotesInChord = this.state.midiNotesInChord
        for (let chordNote of midiNotesInChord){
            this.props.sendMidi(chordNote, true)
        }
        console.log("####################")
    }

}

export default AdvancedChordButton;