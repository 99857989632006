import React, { Component } from 'react';
import ChordButton from './chordButton'
import * as Chord from "tonal-chord";
var PcSet = require("tonal-pcset")



class ChordColumn extends Component {

    constructor(props) {
        
        super(props);
        this.state = {
            chords: []
        };
      }

    render() {

        return (
            <React.Fragment>
             <div className="chordRow">
            
                {
                this.getChords().map((item=>
                    <ChordButton    key={this.props.note + "-" + item.substr(this.props.note.length)} 
                                    note={ (this.props.note === "E#" ? "F" : (this.props.note === "B#" ? "C" : this.props.note ))}
                                    chord ={item.substr(this.props.note.length)} 
                                    oct={this.props.oct} 
                                    rootNote={this.props.rootNote}
                                    playChord={this.playChord}
                                    setCurrentChord={this.setCurrentChord}
                                    sendMidi={this.sendMidi} 
                                    selectable={this.props.selectable}
                                    selectedChord={this.selectedChord}
                                    configured={this.props.configured}
                                    handleAlert={this.handleAlert}
                    />
                ))
                }
             
             </div>
            </React.Fragment>
        );
    }

    getChords(){
        let chords = []     //Scale-Matching Chords 
        
        const inScale = PcSet.isSubsetOf(this.props.notesOfScale)
        for(let chord of this.props.possibleChords){
            
            if(inScale(Chord.notes(this.props.note + chord))){
                if(this.restrictedChords(chord)){
                    chords.push(this.props.note + chord)
                }
            }

        }
        return(chords)
    }

    restrictedChords(chord){
        let restrChords = ["5", "4", "69#11"]
            if(restrChords.includes(chord)){
                return false
            } else {
                return true
            }
    }

    isNoteInScale(chordNote){
        for(let scaleNote of this.props.notesOfScale){
            if(scaleNote !== chordNote){
                return false;
            }
            if(scaleNote === chordNote){
                //is part of scale
                return true
            }
        }
    }

    //Pass functions to parent
    sendMidi = (input, stopInput) =>{
        this.props.sendMidi(input, stopInput)
    }

    handleAlert = () =>{
        this.props.handleAlert(true)
    }

    setCurrentChord = (chordNotes, isOff) =>{
        this.props.setCurrentChord(chordNotes, isOff)
    }

    playChord = (note, chord) => {
        this.props.playChord(note,chord)
    }

    selectedChord = (note, chord) => {
        this.props.selectedChord(note, chord)
    }
     
}

export default ChordColumn;
