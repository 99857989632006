import React, { Component } from 'react';
import './App.css';
import Midi from './components/midi'

class App extends Component {
  constructor(props) {

    super(props);
    this.state = {

    };
  }
  render() {
    return (
      <React.Fragment>
      <div className="App">

        <Midi showModal={this.state.showModal} midiOut={this.midiOut}/>
      </div>

      </React.Fragment>
    );
    
  }

  midiOut = (midiOut) =>{
    if(midiOut){
      this.setState({midiOut:true})
    }
    else{
      this.setState({midiOut:false})
    }
  }


  openModal = () =>{
    this.setState({
      showModal: !this.state.showOptions
    })
  }
}

export default App;
