import React, { Component } from 'react';

import ChordColumn from './chordColumn';
import ScrollArea from 'react-scrollbar';
import AdvancedChordButton from './advancedChordButton';
import Modal from 'react-modal';
import 'react-tippy/dist/tippy.css'
import * as Scale from "tonal-scale";
import * as Chord from "tonal-chord";
import * as Note from "tonal-note";
import {Tooltip, } from 'react-tippy';

var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
let firedDown = false;

class Midi extends Component {
    

    constructor(props) {

        super(props);

        this.state = {
            midi: [],
            inputs: [],
            outputs: [],
            outputDeviceName:"",
            outputDeviceId: "",
            outputNames: [],
            inputNames: [],
            possibleChords: [],
            editable: false,
            midiOut: false,
            midiIn: false,
            inputDeviceId: "",
            inputDeviceName: "",
            allNnotes:[],
            oct: 4,
            rootNote: "C",
            velocity: "5f",    // hex
            velSlider: 75,     // range: 0 - 100
            scales:[],
            scale: "major",
            notesOfScale: "",
            chordsInScale: [],
            showAlert: "hide",
            showApp:false,
            configured: false,
            noteOn: "",
            noteOff: "",
            currentChord: [],
            showModal: true,
            selectable:false,
            selectedChords:[],
            showMappedChords:false,
            showWarnModal: false,
            mappedChords: []
        };
    }



    /* 
        TODO:
        -remove: C## Display Bugs
        -add keyhandler
    */

    render() {

        if (!navigator.requestMIDIAccess) {
                return <div className="alert">WebMIDI is not supported in this browser. Sorry. Check out Chrome.</div>
            } 
        else {      
            return (
                <React.Fragment >
                   
                    <Modal 
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        className="Modal"
                        outputDeviceName={this.state.outputDeviceName}
                        inputDeviceName={this.state.inputDeviceName}
                        overlayClassName="OverlayStart"
                        appElement={document.getElementById('root')}
                    >
                    
                    
                    <div className="logo">
                    <img src="midi-pushr.png" alt="MIDI PUSHR"/>
                        
                    </div>
                    {(isChrome ? "" :   <div className="alert alert-info mb-5" role="alert">
                                                    Uhm... Web MIDI API is not supported by your browser, - in order to use this app, please use <a href="https://www.google.com/intl/de_ALL/chrome/" rel="noopener noreferrer" target="_blank">Google Chrome</a>
                                        </div>)}
                    <div className="row">
                        <div className="col-md-4">
                            <h2><img alt="" src="icons/plug.svg"/> MIDI-Device Options </h2>
                            <label htmlFor="exampleFormControlSelect1">Select Midi Output:</label>
                            <select 
                                className="form-control" 
                                id="midiOutSelect" defaultValue={this.props.outputDeviceName === "" ? "" : this.state.outputDeviceName} 
                                onChange={e => this.setOutputDevice(e)}>
                            
                                <option defaultValue=""></option> 
                                      
                                {
                                    this.state.outputNames.map((item=>
                                        <option key={item}>{item}</option>
                                    ))
                                }

                            </select>   
                            <label htmlFor="selectMidiIn">Select Midi Input:</label>
                            <select 
                                className="form-control" 
                                id="midiInSelect" 
                                defaultValue={this.props.inputDeviceName === "" ? "" : this.state.inputDeviceName} 
                                onChange={e => this.setInputDevice(e)}>
                                
                                <option defaultValue=" " disabled></option>
                            
                                {
                                    this.state.inputNames.map((item=>
                                        <option key={"input-" + item}>{item}</option>
                                    ))
                                }

                            </select>
                        </div>
                            
                        <div className="col-md-4">
                            <h2><img alt="" src="icons/music-alt.svg"/> Chord Options</h2>
     
                            <label htmlFor="exampleFormControlSelect1">Select Root Note:</label>
                            <select className="form-control" id="midiOutSelect" defaultValue={this.state.rootNote} onChange={e => this.setRootnote(e)}>
                                {
                                    this.state.allNnotes.map((item =>
                                        <option key={"output-" + item}>{item}</option>
                                    ))
                                }
                            </select>

                            <label htmlFor="exampleFormControlSelect1">Select Scale:</label>
                            <select className="form-control" id="scaleSelect" value={this.state.scale} onChange={e => this.setScale(e)} >
                                {
                                    this.state.scales.map((item=>
                                        <option key={"scale-" + item }>{item}</option>
                                        ))
                                }
                            </select>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="formControlRange">Velocity</label>
                                    <input type="range" className="form-control-range" min="0" max="100" value={this.state.velSlider} onChange={e => this.setVelocity(e)} id="formControlRange" />
                                </div>
                            </form>

                            <label htmlFor="exampleFormControlSelect1">Octave:</label>
                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                    <button type="button" onClick={e => this.setOct(-1)} className="btn btn-light btn-secondary">-1</button>
                                    <button type="button" className="btn btn-secondary disabled">{this.state.oct}</button>
                                    <button type="button" onClick={e => this.setOct(+1)} className="btn btn-light btn-secondary">+1</button>
                                </div>
                        </div>
                        
                        <div className="col-md-4">
                            <h2><img src="icons/help.svg" alt=""/> How to use</h2>
                            <ul className="liSpacer">
                                <li>Start up your favourite DAW</li>
                                <li>Activate an unused virtual MIDI-Input-Device in your DAW.
                                    For virtual MIDI Devices I recommend <a target="_blank" rel="noopener noreferrer" href="http://www.tobias-erichsen.de/software/loopmidi.html">loopMIDI</a>.
                                </li>
                                <li>Set MIDI Output to your virtual MIDI-Device</li>
                                <li>Optionally you can set MIDI Input to a MIDI-Controller to play mapped chords with one key // middle C upwards...</li>
                            </ul>

                            <form className="donate grayscale" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="9WQP9XAXB2KXS" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            </form>


                        </div>
                    </div>
                    <button className="modal-btn" onClick={e => this.closeModal()}>Compose <img src="icons/angle-right.svg" alt="Compose"/></button>
                </Modal>
                

                <div className={(this.state.showApp ? "show" : "hide")}>
                <div className="topline">

                    <img className="mainLogo" src="midi-pushr.png" alt="MIDI PUSHR"/>
                    <div className="mainOptions">

                    <div className="midiStatus">
                        <div className="statusDescr">Midi In:</div><div className={"midiStatusIcon " + (this.state.midiIn ? "midiActive" : "")}></div>
                        <div className="statusDescr">Midi Out:</div><div className={"midiStatusIcon " + (this.state.midiOut ? "midiActive" : "")}></div>   
                    </div>

                    <Tooltip
                        // options
                        title="Global Options"
                        position="top"
                        trigger="mouseenter"
                        >
                    <div className="optionIcon"><img src="icons/panel.svg" alt="Options" onClick={e => this.openModal(e)}></img></div>
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Select Chords for MIDI-Mapping"
                        position="top"
                        trigger="mouseenter"
                        >
                    <img 
                        className="configChord" 
                        src="icons/clip.svg"
                        alt=""
                        onClick={e => this.setSelectable()}
                        
                        />
                    </Tooltip>

                    </div>
                </div>


                <ScrollArea
                    speed={0.8}
                    className="area"
                    contentClassName="content"
                >
                    <div className="chordWrapper">
                        <div className={"alert alert-warning " + this.state.showAlert} role="alert">
                            You need to select 'Midi Output' first.
                        </div>
                        
                        {
                            Scale.notes(this.state.rootNote, this.state.scale).map((note=>
                                <ChordColumn
                                    key={"chordColumn-" + note}
                                    sendMidi ={this.sendMidi}
                                    note={note}
                                    rootNote = {this.state.rootNote}
                                    scale={this.state.scale} 
                                    configured={this.state.configured}
                                    setCurrentChord={this.setCurrentChord}
                                    handleAlert={this.handleAlert}
                                    notesOfScale={Scale.notes(this.state.rootNote, this.state.scale)}
                                    oct={this.state.oct}
                                    possibleChords={Chord.names()}
                                    selectedChord={this.selectedChord}
                                    selectable={this.state.selectable}
                                    />   
                            ))
                        }

                    </div>
                </ScrollArea>
                <div className="dropZoneTrigger" onClick={e => this.toggleMappedChords()}>
                        <img src="icons/angle-down.svg" alt="" className={(this.state.showMappedChords === false ? "" : "rotate180")}/> {(this.state.showMappedChords === false ? "SHOW" : "HIDE")} MIDI MAPPED CHORDS
                </div>
                <div className={"dropZone " + this.showMappedChords()}>
                    <div className="dropZoneWrap">

                        {
                            (this.state.selectedChords.length === 0 ? 
                                <div className="mapMsg">
                                <img src="./icons/info-alt.svg" alt=""/> No MIDI-mapped chords yet - Use the "clip" button to map chords.</div>
                    
                                : "")
                        }

                {
                    this.state.selectedChords.map((item, index)=>
                        <AdvancedChordButton    
                                        key={index + "_" + item}
                                        index={index}
                                        ref={(advancedChordButton) => {window.advancedChordButton = advancedChordButton}}
                                        note={item.charAt(0).toUpperCase() + (item.charAt(1) === "#" ? "#" : "")} 
                                        chord ={(item.charAt(1) === "#" ? item.substr(2) : item.substr(1))} 
                                        oct={this.state.oct} 
                                        rootNote={this.props.rootNote} 
                                        setCurrentChord={this.setCurrentChord}
                                        sendMidi={this.sendMidi}
                                        configured={this.props.configured}
                                        editable={this.state.editable}
                                        selectedChord={this.selectedChord}
                                        midiInData={this.state.midiInData}
                                        setMappedChords={this.setMappedChords}
                                        selectedChords={this.state.selectedChords}
                                        deleteAdvancedChordButton={this.deleteAdvancedChordButton}
                                        moveAdvancedChordButton = {this.moveAdvancedChordButton}
                                        hideApp = {this.hideApp}
                        />
                    )
                }
                </div>
                <div className="midiMappedButtons">
                    <div className="editChordsBtn" onClick={e => this.setAdvancedChordButtonEditable()}>
                        <Tooltip
                                title="Edit MIDI-Mapped Chords"
                                position="left"
                                trigger="mouseenter"
                                >
                            <img alt="" src="icons/ruler-pencil.svg"/>
                        </Tooltip>
                    </div>
                    <div className="deleteAllChordsBtn" onClick={e => this.showWarning()}>
                        <Tooltip
                                title="Delete all chords"
                                position="left"
                                trigger="mouseenter"
                                >
                            <img src="icons/trash.svg" alt="" />
                        </Tooltip>
                    </div>

                </div>
            </div>
            </div>
            <Modal 
                        isOpen={this.state.showWarnModal}
                        contentLabel="Warning"
                        className="Modal warningModal"
                        overlayClassName="OverlayStart"
                    >
                    <h2><img src="icons/alert.svg" alt="" />Are you sure...</h2>
                    ...to delete all mapped chords?
                    <div className="btnwrapper">
                    <button className="modal-warn-btn red" onClick={e => this.deleteAllChords()}>Delete all<img src="icons/angle-right.svg" alt="Delete All"/></button>
                    <button className="modal-warn-btn" onClick={e => this.showWarning()}>Cancel<img src="icons/angle-right.svg" alt="Abort"/></button>
                   
                    </div>
            </Modal>
                
            </React.Fragment>
            );
        }
    }



    setMappedChords = (nr, midiNotes) =>{
        let chords = this.state.mappedChords.slice();
        chords[nr] = midiNotes
        if(chords[nr] === "" || chords[nr] === null){
            chords.splice(nr, 1)
        }
        this.setState({
            mappedChords: chords
        })
    }

    clearMappedChords = () =>{
        this.setState({mappedChords:[]})
    }

    openModal() {
        this.setState({showModal: true, showApp: !this.state.showApp,});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal(){
        this.setState({showModal: false, showApp: !this.state.showApp});
    }
    
    showWarning = () =>{
        if(this.state.selectedChords.length !== 0){
        this.setState({
            showApp: !this.state.showApp,
            showWarnModal: !this.state.showWarnModal
        })
        }   
    }

    deleteAllChords = () =>{
        this.setState({
            selectedChords: [],
            showWarnModal: !this.state.showWarnModal,
            mappedChords:[]
            
        })
        this.hideApp()

    }


    showMappedChords = () =>{
        if(this.state.showMappedChords === true){
            return ("show")
        } else{
            return "hide"
        }
    }

    toggleMappedChords = () =>{
        this.setState({
            showMappedChords: !this.state.showMappedChords
        })
    }


    /*
        Advanced Chord Button Functions
    */

    moveAdvancedChordButton = (index, dir) =>{
        let direction
        let array = [...this.state.selectedChords]; // make a separate copy of the array
        if(dir === "right"){
            direction = 1
                if(index === array.length -1){
                    return;
                }
        } else if(dir === "left"){
            direction = -1
                if(index <= 0){
                    return;
                }
        }else{
            return;
        }
       
        let a = array[index];
        array[index] = array[index + direction];
        array[index + direction] = a;
        this.setState({selectedChords: array});
    }

    deleteAdvancedChordButton = (index) =>{
        let array = [...this.state.selectedChords]; // make a separate copy of the array
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({selectedChords: array});
        }
    }
    
    setAdvancedChordButtonEditable = () =>{
        this.setState({
            editable: !this.state.editable
        })
    }

    

    
    
    gotMIDImessage = (messageData) => {
        
        console.log(messageData)
        console.log(messageData.data)
        //this.setState({midiIn: !this.state.midiIn})
        console.log(messageData.data[2])
        if(messageData.data[2] > 0){
            //console.log("pber 2")
            this.setState({
                midiIn: true
            })

            let nr = this.mapDropChord(messageData.data[1])
            console.log("NR: " + nr)
            if(this.state.selectedChords.length > 0 && nr !== null){

                //this.AdvancedChordButton.playChord(nr);
              // window.advancedChordButton.playChord(nr)
                //window.AdvancedChordButton.playChord(nr)
                
                if(nr <= this.state.mappedChords.length -1){
                    for(let note of this.state.mappedChords[nr]){
                        //console.log("ABOUT SO SEND:" +   note)
                        if(true){
                            this.sendMidi(note)
                        }
                    }
                }
                //this.sendMidi(this.state.selectedChords[nr])
            }
            //this.sendMidi(messageData.data[1])
        }
        
        if(messageData.data[2] === 0){
            //console.log("NULL")
           // this.sendMidi(messageData.data[1], true)
            this.setState({
                midiIn:false
            })
            if(this.state.selectedChords.length > 0){
                let nr = this.mapDropChord(messageData.data[1])
                console.log("NR: " + nr)
                if(this.state.selectedChords.length > 0 && nr !== null){
    
                    //this.AdvancedChordButton.playChord(nr);
                  // window.advancedChordButton.playChord(nr)
                    //window.AdvancedChordButton.playChord(nr)
                    if(nr <= this.state.mappedChords.length -1){
                    for(let note of this.state.mappedChords[nr]){
                        if(nr !== ""){
                        this.sendMidi(note, true)
                    }}
                    
                }
            }
    
                    
    
                    //this.sendMidi(this.state.selectedChords[nr])
                }
                
                //window.AdvancedChordButton.stopChord()
            }
        
    }

    hideApp = () =>{
        this.setState({
            showApp: !this.state.showApp
        })
    }

    getChord(nr){
        if(this.state.selectedChords[nr] !== undefined){
            let item = this.state.selectedChords[nr]
            console.log("item" + item)
            return item.substring(1).toString()
        }
    }

    getNote(nr){
        if(this.state.selectedChords[nr] !== undefined){
            let item = this.state.selectedChords[nr]
            return item.charAt(0)
        }
    }

    mapDropChord(midiNote){
        console.log("midinote: ")
        console.log(midiNote)
        let res = midiNote - 60
        if(res > this.state.selectedChords.length || (res < 0)){
            console.log("no")
            return null
        }
        console.log("res: "+ res)
        return res
    }

    selectedChord = (note, chord) =>{
        let chords = this.state.selectedChords
        chords.push(note + chord)
        this.setState({selectedChords: chords})
        //console.log(chords)
    }


    setSelectable = () =>{
        this.setState({
            selectable: !this.state.selectable,
            showMappedChords: true
        })
    }

    setCurrentChord = (chordNotes, isOff) =>{
        if(!isOff){
        this.setState({
            currentChord: chordNotes
        })} else{
            this.setState({
                currentChord: []
            })
        }
    }

    handleAlert = () => {
        this.setState({
            showAlert: "show"
        })
    }

    setOct = (val) =>{
        this.setState({
            oct: (this.state.oct + val)
        })
    }

    setChordsOfScale(){
          console.log("CHORDS OF SCALE:")
          console.log(Scale.chords("Cmajor"))
    }

    setScale = (e) => {
        this.setState({
            scale: e.target.value,
            chordsInScale: Scale.chords(this.state.rootNote + " " + e.target.value)
        })
    }

    setRootnote = (e) => {
        this.setState({
            rootNote: e.target.value
        })
    }

    setVelocity = (e)=> {
        this.setState({
            velSlider: (e.target.value),
            velocity: (Math.round(e.target.value*1.27).toString(16))
        })
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    mapChord = (note) =>{
         this.sendMidi(Note.midi(note + "4"))
    }




    sendMidi = (input, stopInput) =>{
        //let noteOnMessage = [0x90, 61, 0x7f];       // note on, middle C, full velocity
        var output = this.state.midi.outputs.get(this.state.outputDeviceId);     
        //let output = this.state.outputDeviceId
        if(!stopInput){
            output.send( [0x90, input , "0x" + this.state.velocity] );  //omitting the timestamp means send immediately.
            this.setState({midiOut: true})
            console.log("MIDI-Send-Play: " + input)
        }
        if(stopInput){
            output.send( [0x80, input, 0x40], window.performance.now());
            this.setState({midiOut: false})
           // output.send( [0x80, input, 0x40], window.performance.now() + 1000.0 ); // Inlined array creation- note off, middle C,
        console.log("MIDI-Send-Stop: " + input)
        }
        //console.log(output)
      
    }

    

    

    //Midi Status
    midiOut = (bool) =>{
        this.props.midiOut(bool);
    }


    setOutputDevice = (e) =>{
        this.state.outputs.forEach((port=>e.target.value === port.name ? this.setState({outputDeviceId: port.id}) : null ))
        this.setState({
            outputDeviceName: e.target.value,
            configured:true,
            showAlert: "hide"
        })
    }

    setInputDevice = (e) =>{
        this.state.inputs.forEach(
            (port=>e.target.value === port.name ? this.setState({inputDeviceId: port.id}) : null ))
        this.setState({
            inputDeviceName: e.target.value,
        })

        var allInputs = this.midi.inputs.values();

        for (var input = allInputs.next(); input && !input.done; input = allInputs.next()) {
            // when a MIDI value is received call the onMIDIMessage function
            console.log(input.value.name)
            if(input.value.name === e.target.value){    
                input.value.onmidimessage = this.gotMIDImessage.bind(this);
            }
        }
    }

    componentDidMount(){
        this.navigator = require('web-midi-api');
        this.setState({
            allNnotes: Note.names(" #"),
            scales: Scale.names(),
        })
        this.navigator.requestMIDIAccess().then(this.onMIDISuccess, this.onMIDIFailure); 
        document.addEventListener('keypress', e => this.handleKeyDown(e));  
        document.addEventListener('keyup', e => this.handleKeyUp(e));  
    }

    
    handleKeyDown = (e) =>{
        if(!firedDown){
            firedDown = true;
            this.playMappedChord(e.keyCode)
            console.log("keyd " + e.keyCode)
        }
    }

    handleKeyUp = (e) =>{
        console.log("up");
        this.playMappedChord(e.keyCode, true)
        firedDown = false;
    }

    //TODO:     1-9
    //          check for numpad keys
    playMappedChord = (key, stop) =>{
        let chordID = ""
        switch(key) {
            case (49):
                chordID = 0
                break;
            case (50):
                chordID = 1
                break;
            case (51):
                chordID = 2
            break;
            case (52):
                chordID = 3
                break;
            case (53):
                chordID = 4
                break;
            case (54):
                chordID = 5
                break;
            case (55):
                chordID = 6
                break;
            case (56):
                chordID = 7
                break;
            case (57):
                chordID = 8
                break;
            case (58):
                chordID = 9
                break;
        default:
            break;
        }

        if(!stop){
            if(this.state.mappedChords[chordID] !== undefined){
                for(let note of this.state.mappedChords[chordID]){
                    this.sendMidi(note)
                }
            }
        }
        if(stop){
            if(this.state.mappedChords[chordID] !== undefined){
                for(let note of this.state.mappedChords[chordID]){
                    this.sendMidi(note, stop)
                }
            }
        }
    }


    onMIDISuccess = (midiAccess) => {
        let outs = []
        let ins = []
        midiAccess.outputs.forEach((port)=>{
            //console.log(port.name)
            outs.push(port.name)
            ins.push(port.name)
        })
        this.midi = midiAccess;
        this.setState({
            midi: midiAccess,
            inputs: midiAccess.inputs,
            outputs: midiAccess.outputs,
            outputNames: outs,
            inputNames: ins 
        })
      

        console.log("MIDI access successfull")
        setTimeout(this.testOutputs.bind(this), 500);
    }


    onMIDIFailure(msg){
        console.log('Failed to get MIDI access - ' + msg);
        process.exit(1);
    }

    testOutputs(){
        console.log('Testing MIDI-Out ports...');
        this.state.outputs.forEach(function(port){
          //console.log('id:', port.id, 'manufacturer:', port.manufacturer, 'name:', port.name, 'version:', port.version);
          port.open();
          //port.send([0x90, 60, 0x7f]);
        });
        setTimeout(this.stopOutputs.bind(this), 1000);
      }

      stopOutputs(){
        this.state.outputs.forEach(function(port){           
          port.send([0x80, 60, 0]);
        });
        this.testInputs();
      }

      onMidiIn = (ev) =>{
        var arr = [];
        for(var i = 0; i < ev.data.length; i++){
          arr.push((ev.data[i] < 16 ? '0' : '') + ev.data[i].toString(16));
        }
        console.log('MIDI:', arr.join(' '));
      }

      testInputs(){
        console.log('Testing MIDI-In ports...');
        //this.state.inputs.forEach(function(port){
        //console.log('id:', port.id, 'manufacturer:', port.manufacturer, 'name:', port.name, 'version:', port.version);
        //port.onmidimessage = this.onMidiIn();
        //});
        //setTimeout(()=> this.stopInputs, 5000);
      }

      stopInputs(){
        console.log('Thank you!');
        navigator.close(); // This will close MIDI inputs, otherwise Node.js will wait for MIDI input forever.
        //process.exit(0);
      }
      
}


export default Midi;

  
