import React, { Component } from 'react';
import * as Chord from "tonal-chord";
import * as Note from "tonal-note";

/*

    PROPS: 
    -note:      note of chord in lowercase (eg. c)
    -chord      chord-name (eg. "M13")
    -sendMidi   (function)


*/


class ChordButton extends Component {
    node = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            chord: []
        };
    }

    
    componentDidMount() {
        this.node.current.ontouchstart = this.toggle;


        // Readable Notes
        let notesInChord = Chord.notes(this.props.note + this.props.chord)
        let midiNotesInChord = []

        // MIDI Notes
        let prevNote = 60
        let count = 0
        for (let chordNote of notesInChord){
            let note = Note.midi(chordNote + this.props.oct)
            count += 1;
            if (note < prevNote){
                note += 12
            }
            //console.log(note)
            prevNote = note
            midiNotesInChord.push(note)
        }

        this.setState({
            oct: this.props.oct,
            notesInChord: notesInChord,
            midiNotesInChord: midiNotesInChord
        })


    }


   
    render() {
        let midiInData = this.props.midiInData;
        if(midiInData === true){
            
                (this.playChord((this.props.note).toLowerCase(), this.props.chord))
            
        }

        return (
            <React.Fragment>
                <div className={"chordButton " + (this.props.selectable ? "active" : "") } key={this.props.note} 
                    onMouseDown={e => {e.preventDefault(); this.playChord((this.props.note).toLowerCase(), this.props.chord, true)}} 
                    onTouchStart={e => {e.preventDefault();this.playChord((this.props.note).toLowerCase(), this.props.chord, true)}}
                    onMouseUp={e => {e.preventDefault(); this.stopChord((this.props.note).toLowerCase(), this.props.chord)}}
                    onTouchEnd={e => {e.preventDefault(); this.stopChord((this.props.note).toLowerCase(), this.props.chord)}}
                    ref={this.node}
                >
                    {this.props.note}
                    <div className="chordType">{this.props.chord}</div>
                    <div className="btn-bg"></div>
                </div>
            
            </React.Fragment>
        );
    }
    

    playChord = (note, chord, up) => {    





        if(this.props.selectable){
            if (up === true){
                this.selectedChord(note, chord)
            }   
        }else{

            console.log(note + chord)
            let midiNotesInChord = this.state.midiNotesInChord
            for(let chordNote of midiNotesInChord){
                this.props.sendMidi(chordNote)
            }
            console.log("####################")

/* 
            //let oct = 4
            let prevChordNote = (Note.midi(note + this.props.oct))
            var chordNotesInMidi = []

            console.log("NOTE: " + note)
            console.log("CHORD: " + chord)

            // Plays Chord:
            if(chord !== undefined){
                let notesInChord = Chord.notes(note + chord)
                this.props.setCurrentChord(notesInChord)
                console.log("NOTES IN CHORD: " + notesInChord)
                for(let chordNote of notesInChord){
                    //console.log("chordNote: " + chordNote)
                    if (Note.midi(chordNote + this.props.oct) < prevChordNote){
                        // OCT + 1
                        let note  = Note.midi(chordNote + (this.props.oct + 1))
                        chordNotesInMidi.push(note)
                        this.props.sendMidi(note)
                    } 
                    else {
                        let note = Note.midi(chordNote + this.props.oct)
                        chordNotesInMidi.push(note)
                        this.props.sendMidi(note)
                    }
                    prevChordNote = Note.midi(chordNote + this.props.oct)
                }
                chord = chordNotesInMidi
                
                this.setState({
                    chord: chordNotesInMidi
                })
                
                
                
            }
            // Plays Single Note:
            else{
                this.setState({chord: (Note.midi(note + this.props.oct))})
                this.props.sendMidi(Note.midi(note + this.props.oct))
            }*/
        } 
        
    }

    stopChord = () =>{
        // Stops Chord
        let midiNotesInChord = this.state.midiNotesInChord
        for (let chordNote of midiNotesInChord){
            this.props.sendMidi(chordNote, true)
        }
        console.log("####################")
    }


    // pass function to parent
    selectedChord = (note, chord) => {
        this.props.selectedChord(note, chord)
    }

}

export default ChordButton;
