import React, { Component } from 'react';

class Keys extends Component {
 render() {
  return (
   <React.Fragment>
    <div className="keyboard pt-2">


    {/*  OCT 3 */}

    <div className="keyWrapper">       
      <div className="halfKeys">
            <div className={"halfKey cFlat" + this.containsNote(61 - 12)}></div>   {/*   C#   */}
            <div className={"halfKey dFlat" + this.containsNote(63 - 12)}></div>   {/*   d#   */}
            <div className={"halfKey fFlat" + this.containsNote(66 - 12)}></div>   {/*   f#   */}
            <div className={"halfKey gFlat" + this.containsNote(68 - 12)}></div>   {/*   g#   */}
            <div className={"halfKey aFlat" + this.containsNote(70 - 12)}></div>   {/*   a#   */}
       </div>
       <div className="keys">
            <div className={"key " + this.containsNote(60 - 12)}></div>  {/*   C   */}
            <div className={"key " + this.containsNote(62 - 12)}></div>  {/*   D   */}
            <div className={"key " + this.containsNote(64 - 12)}></div>  {/*   E   */}
            <div className={"key " + this.containsNote(65 - 12)}></div>  {/*   F   */}
            <div className={"key " + this.containsNote(67 - 12)}></div>  {/*   G   */}
            <div className={"key " + this.containsNote(69 - 12)}></div>  {/*   A   */}
            <div className={"key " + this.containsNote(71 - 12)}></div>  {/*   B   */}
       </div>
     </div>

    {/*  OCT 4 */}

    <div className="keyWrapper">       
      <div className="halfKeys">
            <div className={"halfKey cFlat" + this.containsNote(61)}></div>   {/*   C#   */}
            <div className={"halfKey dFlat" + this.containsNote(63)}></div>   {/*   d#   */}
            <div className={"halfKey fFlat" + this.containsNote(66)}></div>   {/*   f#   */}
            <div className={"halfKey gFlat" + this.containsNote(68)}></div>   {/*   g#   */}
            <div className={"halfKey aFlat" + this.containsNote(70)}></div>   {/*   a#   */}
       </div>
       <div className="keys">
            <div className={"key " + this.containsNote(60)}></div>  {/*   C   */}
            <div className={"key " + this.containsNote(62)}></div>  {/*   D   */}
            <div className={"key " + this.containsNote(64)}></div>  {/*   E   */}
            <div className={"key " + this.containsNote(65)}></div>  {/*   F   */}
            <div className={"key " + this.containsNote(67)}></div>  {/*   G   */}
            <div className={"key " + this.containsNote(69)}></div>  {/*   A   */}
            <div className={"key " + this.containsNote(71)}></div>  {/*   B   */}
       </div>
     </div>

    {/*  OCT 5 */}

    <div className="keyWrapper">       
      <div className="halfKeys">
            <div className={"halfKey cFlat" + this.containsNote(61 + 12)}></div>   {/*   C#   */}
            <div className={"halfKey dFlat" + this.containsNote(63 + 12)}></div>   {/*   d#   */}
            <div className={"halfKey fFlat" + this.containsNote(66 + 12)}></div>   {/*   f#   */}
            <div className={"halfKey gFlat" + this.containsNote(68 + 12)}></div>   {/*   g#   */}
            <div className={"halfKey aFlat" + this.containsNote(70 + 12)}></div>   {/*   a#   */}
       </div>
       <div className="keys">
            <div className={"key " + this.containsNote(60 + 12)}></div>  {/*   C   */}
            <div className={"key " + this.containsNote(62 + 12)}></div>  {/*   D   */}
            <div className={"key " + this.containsNote(64 + 12)}></div>  {/*   E   */}
            <div className={"key " + this.containsNote(65 + 12)}></div>  {/*   F   */}
            <div className={"key " + this.containsNote(67 + 12)}></div>  {/*   G   */}
            <div className={"key " + this.containsNote(69 + 12)}></div>  {/*   A   */}
            <div className={"key " + this.containsNote(71 + 12)}></div>  {/*   B   */}
       </div>
     </div>

    {/*  OCT 6 */}

    <div className="keyWrapper">       
      <div className="halfKeys">
            <div className={"halfKey cFlat" + this.containsNote(61 + 12 + 12)}></div>   {/*   C#   */}
            <div className={"halfKey dFlat" + this.containsNote(63 + 12 + 12)}></div>   {/*   d#   */}
            <div className={"halfKey fFlat" + this.containsNote(66 + 12 + 12)}></div>   {/*   f#   */}
            <div className={"halfKey gFlat" + this.containsNote(68 + 12 + 12)}></div>   {/*   g#   */}
            <div className={"halfKey aFlat" + this.containsNote(70 + 12 + 12)}></div>   {/*   a#   */}
       </div>
       <div className="keys">
            <div className={"key " + this.containsNote(60 + 12 + 12)}></div>  {/*   C   */}
            <div className={"key " + this.containsNote(62 + 12 + 12)}></div>  {/*   D   */}
            <div className={"key " + this.containsNote(64 + 12 + 12)}></div>  {/*   E   */}
            <div className={"key " + this.containsNote(65 + 12 + 12)}></div>  {/*   F   */}
            <div className={"key " + this.containsNote(67 + 12 + 12)}></div>  {/*   G   */}
            <div className={"key " + this.containsNote(69 + 12 + 12)}></div>  {/*   A   */}
            <div className={"key " + this.containsNote(71 + 12 + 12)}></div>  {/*   B   */}
       </div>
     </div>
    </div>

   </React.Fragment>
  );
 }



 containsNote(note){
/*
  TODO: Hover-Effect for active key

  if(this.props.hover === note){
    return "activeHover"
  }
    else */if (this.props.midiNotesInChord.includes(note)){
      return " activeKey "
     }

  else{
   return ""
  }
 }

}

export default Keys;
